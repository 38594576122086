.sidebar {
  flex: 0;
  background-color: #1F0C6E;
  position: relative;
  top: 0px;
  height: 1024px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.logo{
  cursor: pointer;
  padding-top: 5%;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarMenuBottom {
  margin-bottom: 5px;
  position: bottom;

}

.sidebarTitle {
  font-size: 13px;
  color: #FFFFFF;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #258ee4;
  color: antiquewhite;
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
    color: rgb(255, 0, 174);
}

.active{
  stroke: rgb(95, 93, 94);
  color: antiquewhite;
  filter: invert(74%) sepia(26%) saturate(4921%) hue-rotate(15deg) brightness(119%) contrast(90%);
}

.inactive{
  stroke: #ffffff;
  color: rgb(0, 0, 0);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(245deg) brightness(106%) contrast(104%);
}


